label.thumbnail {
  margin-bottom: 20px;
  border: 1px solid #ddd ;
  background-color: #dfe6ed;

  background-size: cover;
  height: 160px;
  width: 160px;


  display: flex;
  justify-content: center;
  align-items: center;
}

label.thumbnail input {
  display: none;
}

